[data-rk] .iekbcc0 {
  font-size: 13px !important;
}

[data-testid="rk-connect-header-label"].iekbcc0 {
  font-size: 16px !important;
}

[data-rk] .ju367v19 {
  font-size: 11px !important;
}

[data-rk] .ju367v17 {
  font-size: 10px !important;
  margin-top: 1.5px !important;
}

button[data-testid="rk-copy-button"] .iekbcc0.ju367vgu.ju367v16.ju367v18.ju367v1g.ju367v2v {
  font-size: 12px !important;
}

button[data-testid="rk-disconnect-button"] .iekbcc0.ju367vgu.ju367v16.ju367v18.ju367v1g.ju367v2v {
  font-size: 12px !important;
}